import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { networkLayer } from "./network/client";

import { RootState } from "./store/store";

export interface TouchState {
    emulate: boolean;
    recording: boolean;
    lastTouchTime: number|null;
    id: number | null;
    splashIndex: number ;
}
const initialState: TouchState = {
    emulate: false,
    recording: false,
    id: null,
    lastTouchTime: null,
    splashIndex:1
}

export const recordStart = createAsyncThunk(
    'app/recordStart',
    async () => {
        return await networkLayer.touchService.touchStart()
    }
)
export const recordStop = createAsyncThunk(
    'app/recordStop',
    async () => {
        return await networkLayer.touchService.touchStop()
    }
)
function delay(duration:number) {
    return new Promise( (resolve, reject) => {
        setTimeout( ()=>{
            resolve({});
        }, duration)
    });
}
export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        changeSplashIndex(state){
            state.splashIndex = (state.splashIndex+1)%5
        },
        touchEnd(state){
            state.emulate = false;
            state.recording = false;
            state.id = null
        },
        touch(state){
            state.lastTouchTime = new Date().getTime()
            state.emulate = false;
            state.recording = true;
            state.id = null
            console.log("touch detected")
        },
        locationChange(state,action){
            if (state.recording){
                
            } else {
                
            }
        }
    },
    extraReducers: (builder) => {
       
        // builder.addCase(recordStart.pending, (state: TouchState, action) => {
        //     // HANDLE MY UPLOAD_START ACTION
           
        // })
        // builder.addCase(recordStart.fulfilled, (state: TouchState, action) => {
        //     // HANDLE MY UPLOAD_START ACTION
        // })
        // builder.addCase(recordStart.rejected, (state: TouchState, action) => {
        //     // HANDLE MY UPLOAD_START ACTION
        // })

        // builder.addCase(recordStop.pending, (state: TouchState, action) => {
        //     // HANDLE MY UPLOAD_START ACTION
        // })
        // builder.addCase(recordStop.fulfilled, (state: TouchState, action) => {
        //     // HANDLE MY UPLOAD_START ACTION
        // })
        // builder.addCase(recordStop.rejected, (state: TouchState, action) => {
        //     // HANDLE MY UPLOAD_START ACTION
        // })
    },
})

export const { locationChange ,changeSplashIndex,touch,touchEnd} = appSlice.actions


export const selectTouchEmulate = (state: RootState) => state.touch.emulate
export const selectTouchData = (state: RootState) => state.touch
export const selectLastTouchTime = (state: RootState) => state.touch.lastTouchTime
export const selectSplashIndex = (state: RootState) => state.touch.splashIndex

export default appSlice.reducer
